import React, {Component} from 'react';
import LiveContent from "./component/layout/LiveContent";
import {connect} from "react-redux";
import ActionLiveType from "./reducer/live/ActionLiveType";
import Live from "../../../../../../server/common/Models/live/Live";
import socketIOClient from "socket.io-client";
import ReferentialType, {
    ReferentialRaceType,
    ReferentialSessionType
} from "./type/ReferentialType";
import Entry from "../../../../../../server/common/Models/live/Entry";
import {SessionStatusEnum} from "../../../../../../server/common/Enum/live/SessionTypeEnum";
import LiveKeynote from "./component/fragment/LiveKeynote";
import LiveRaceEvent from "./component/fragment/LiveRaceEvent";
import LiveNews from "./component/fragment/LiveNews";
import ReducerType from "./type/ReducerType";
import {NetEventsEnum} from "../../../../../../server/common/Enum/live/NetEventsEnum";
import Params from "../../../../../../server/common/Models/live/Params";
import FlagProgress from "../../../../../../server/common/Models/live/FlagProgress";
import ActionTunnel from "./reducer/tunnel/ActionTunnel";
import {history} from "./reducer/configureStore";
import {Route, Switch} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import {Layout} from "./component/layout/Layout";
import StatScreenLayout from "./component/layout/StatScreenLayout";
import {t} from "./translation/translator";
import SessionStats from "./component/fragment/sessionStats/SessionStats";
import {NavigationEnum} from "./enum/NavigationEnum";
import EntryStints from "./component/fragment/stints/EntryStints";
import ActionStints from "./reducer/stints/ActionStints";
import ParticipantStints from "../../../../../../server/common/Models/alkamel/ParticipantStints";
import DriverStints from "./component/fragment/stints/DriverStints";
import Socket = SocketIOClient.Socket;
import CarStatsContainer from "./component/fragment/carStats/CarStatsContainer";
import ParticipantLaps from "../../../../../../server/common/Models/alkamel/ParticipantLaps";
import ActionLaps from "./reducer/laps/ActionLaps";
import CarComparisonContainer from "./component/fragment/carStats/CarComparisonContainer";
import SectorOrLap from "../../../../../../server/common/Models/alkamel/SectorOrLap";
import RaceControlMessage from "../../../../../../server/common/Models/alkamel/RaceControlMessage";
import ActionRaceControl from "./reducer/race_control/ActionRaceControl";
import RaceControlPage from "./component/fragment/RaceControl/RaceControlPage";
import RequestLoginModal from "./component/fragment/RequestLoginModal";
import {inIframe} from "./reducer/live/reducer";
import LiveTable from "./component/layout/LiveTable";

declare var reactParameters;

const mapStateToProps = (
    _state: ReducerType,
    ownProps: { home: string, social: string, configUrl: string },
) => ({
    championship: _state.live.championship,
    home: ownProps.home,
    socialNetworkUrl: ownProps.social,
    configUrl: ownProps.configUrl,
    race: _state.live.referential.race,
    lang: _state.live.lang,
    currentSession: _state.live.currentSession,
    selectedMenu: _state.live.selectedMenu,
    socket: _state.live.socket,
});


const storeDispatchToProps = (dispatch) => ({
    updateSocket: (socket: Socket) => dispatch(ActionLiveType.updateSocket(socket)),
    updateSocialNetworks: (sn: []) => dispatch(ActionLiveType.updateSocialNetworks(sn)),
    updateLive: (live: Live) => dispatch(ActionLiveType.updateLive(live)),
    updateParams: (params: Params) => dispatch(ActionLiveType.updateParams(params)),
    updateEntries: (entries: Entry[]) => dispatch(ActionLiveType.updateEntries(entries)),
    updateBestSectors: (sectors: SectorOrLap[]) => dispatch(ActionLiveType.updateBestSectors(sectors)),
    clearEntries: () => dispatch(ActionLiveType.clearEntries()),
    updateResults: (results: any) => dispatch(ActionLiveType.updateResults(results)),
    updateReferential: (ref: ReferentialType) => dispatch(ActionLiveType.updateReferential(ref)),
    updateSessions: (sessions: ReferentialSessionType[]) => dispatch(ActionLiveType.updateSessions(sessions)),
    connect: () => dispatch(ActionLiveType.connect()),
    disconnect: () => dispatch(ActionLiveType.disconnect()),
    setName: (name: string) => dispatch(ActionLiveType.setName(name)),
    setChannels: (channels: []) => dispatch(ActionLiveType.setChannels(channels)),
    setChannel: (url: string) => dispatch(ActionLiveType.setChannel(url)),
    updateChannelId: (id: number | null) => dispatch(ActionLiveType.updateChannelId(id)),
    updateChannels: (cb: () => void) => dispatch(ActionLiveType.updateChannels(cb)),
    updateInfos: (cb: (loggedIn: boolean) => void) => dispatch(ActionLiveType.updateInfos(cb)),
    initSocialNetworks: (url: string) => dispatch(ActionLiveType.initSocialNetworks(url)),
    initResult: (currentSession: ReferentialSessionType, race: ReferentialRaceType, lang: string) => dispatch(ActionLiveType.initResult(currentSession, race, lang)),
    initTunnelDisplayFromCookie: () => dispatch(ActionTunnel.initTunnelDisplayFromCookie()),
    initExpertFromCookie: () => dispatch(ActionLiveType.initExpertFromCookie()),
    connectToRaceControlSocket: (socket: Socket) => dispatch(ActionRaceControl.connectToRaceControlSocket(socket)),

    updateStints: (stints: ParticipantStints[]) => dispatch(ActionStints.updateStints(stints)),
    updateLaps: (laps: ParticipantLaps[]) => dispatch(ActionLaps.updateLaps(laps)),
    updateFlags: (flags: FlagProgress[]) => dispatch(ActionLiveType.updateFlags(flags)),
    updateRaceControl: (messages: RaceControlMessage[]) => dispatch(ActionRaceControl.updateRaceControl(messages)),
    calculateDriverStints: (stints: ParticipantStints[]) => dispatch(ActionStints.calculateDriverStints(stints)),
    connectToStintsSocket: () => dispatch(ActionStints.connectToStintsSocket()),
    disconnectFromStintsSocket: () => dispatch(ActionStints.disconnectFromStintsSocket()),
    connectToLapsSocket: () => dispatch(ActionLaps.connectToLapsSocket()),
    disconnectFromLapsSocket: () => dispatch(ActionLaps.disconnectFromLapsSocket()),

    toggleDark: (dark: boolean) => dispatch(ActionLiveType.toggleDark(dark)),
    switchLang: (lang: string) => dispatch(ActionLiveType.switchLang(lang))
});
type Props = {
    liveHome: string,
    socialNetworkUrl: string,
    configUrl: string,
    account: string,
    packsUrl: string,
    acoUrl: string,
    member?: any,
    session?: number,
    raceId: number,
    forLemans: boolean,
    dark: boolean,
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof storeDispatchToProps>;
type State = { ws: string };

export class LiveMainView extends Component<Props, State> {

    constructor(props, state) {
        super(props, state);

        const params = new URLSearchParams(window.location.search);
        const port = params.get('port');
        let server: string = reactParameters.wsUrl;
        if (port) {
            let tab = server.split(":");
            server = tab[0] + ":" + tab[1] + ":" + port;
        }
        this.state = {
            ws: server
        };
    }

    componentDidMount(): void {
        if (this.props.dark) {
            this.props.toggleDark(true);
        }
        this.props.initTunnelDisplayFromCookie();

        // if (this.props.championship.id === 1 && false === reactParameters.elms) {
        //     this.props.updateChannels(() => {
        //         // on affiche la vidéo par défaut selon la langue
        //         this.props.updateChannelId(null);
        //     });
        // }
        if (this.props.championship.id === 1 && false === reactParameters.elms) {
            this.props.initExpertFromCookie();
        } else {
            this.props.updateInfos(
                (loggedIn => {
                    if (loggedIn) {
                        this.props.initExpertFromCookie();
                    }
                })
            );
        }
        if (null !== this.props.raceId) {
            // get referential
            let raceId = this.props.raceId;
            const location = window.location;
            let query = new URLSearchParams(location.search);
            let forced = false;
            if (query.get("session")) {
                forced = true;
            }
            fetch(reactParameters.refUrl + raceId + ".json?t=" + Math.random()).then((response) => {
                //fetch(reactParameters.refUrl + raceId + ".json").then((response) => {
                response.json().then((ref) => {
                    this.props.updateReferential(ref);
                    // on update les sessions independamment, avec le parametre pour invalider le cache
                    fetch(reactParameters.refUrl + 'sessions/' + raceId + ".json?t=" + Math.random()).then(response => response.json().then(sessions => {
                        this.props.updateSessions(sessions);
                        if (this.props.currentSession) {
                            if (this.props.currentSession.status == SessionStatusEnum.LIVE || forced) {
                                // init ws and update data course
                                this.initSocket();
                            } else {
                                // results :
                                this.initResult(this.props.currentSession);
                            }
                        }
                    }));
                    this.props.initSocialNetworks(this.props.socialNetworkUrl);
                })
            });
        }
        let pathArray = window.location.pathname.split('/');
        for (let elem of pathArray) {
            switch (elem) {
                case 'fr':
                    this.props.switchLang('fr');
                    break;
                case 'en':
                    this.props.switchLang('en');
                    break;
            }
        }
    }

    initSocket() {
        let socket = socketIOClient(this.state.ws, {secure: this.state.ws.includes("wss")});

        socket.on(NetEventsEnum.params, (params: Params) => {
            this.props.updateParams(params);
        })

        socket.on(NetEventsEnum.entries, (entries: Entry[]) => {
            this.props.updateEntries(entries);
        })
        socket.on(NetEventsEnum.best_sectors, (sectors: SectorOrLap[]) => {
            this.props.updateBestSectors(sectors);
        })

        socket.on(NetEventsEnum.clear_entries, () => {
            this.props.clearEntries();
        })

        socket.on(NetEventsEnum.stints, (stints: Array<ParticipantStints>) => {
            this.props.updateStints(stints);
            // mise à jour des stints drivers
            this.props.calculateDriverStints(stints)
        })
        socket.on(NetEventsEnum.laps, (laps: Array<ParticipantLaps>) => {
            this.props.updateLaps(laps);
        })
        socket.on(NetEventsEnum.flags, (flags: Array<FlagProgress>) => {
            this.props.updateFlags(flags);
        })
        socket.on(NetEventsEnum.race_control, (messages: Array<RaceControlMessage>) => {
            this.props.updateRaceControl(messages);
        })

        socket.on("race", (data: Live) => {
            this.props.updateLive(data);
        });
        socket.on("refresh", () => {
            window.location.reload();
        });
        this.props.updateSocket(socket);
        this.props.connectToRaceControlSocket(socket);
        this.props.connectToLapsSocket();
        this.props.connectToStintsSocket();
    }

    closeSocket() {
        this.props.socket.close();
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
        if (this.props.selectedMenu != nextProps.selectedMenu) {
            // changement menu :
            return true;
        }
        if (this.props.lang != nextProps.lang) {
            // changement menu :
            return true;
        }
        if (!this.props.currentSession && nextProps.currentSession) {
            // nouvelle session :
            return true;
        }
        if (this.props.currentSession && nextProps.currentSession &&
            (nextProps.currentSession.status != this.props.currentSession.status || nextProps.currentSession.id != this.props.currentSession.id)) {
            // changement de statut d'une session :
            if (nextProps.currentSession.status == SessionStatusEnum.LIVE) {
                // on lance
                this.initSocket();
            }
            if (this.props.currentSession.status == SessionStatusEnum.LIVE) {
                // on coupe
                this.closeSocket();
            }
            this.initResult(nextProps.currentSession);
            return true;
        }
        return false
    }

    initResult(session: ReferentialSessionType) {
        // on demande au serveur node s'il a les stats de la session en mémoire :
        fetch(reactParameters.httpUrl + "data/" + session.alkamel_id).then(response => {
            response.json().then(data => {
                // pas de data : on se rabat sur les resultats eventuels
                if (data.message) {
                    this.props.initResult(session, this.props.race, this.props.lang);
                } else {
                    this.props.updateParams(data.params);
                    this.props.updateEntries(data.entries);
                    this.props.updateFlags(data.progressFlagState);
                    this.props.updateBestSectors(data.bestSectors);

                    fetch(reactParameters.httpUrl + "stints/" + session.alkamel_id).then(response => {
                        response.json().then(data => {
                            this.props.updateStints(data);
                            this.props.calculateDriverStints(data);
                        })
                    })
                    fetch(reactParameters.httpUrl + "laps/" + session.alkamel_id).then(response => {
                        response.json().then(data => {
                            this.props.updateLaps(data)
                        })
                    })
                }
            })
        })
            .catch(e => console.log(e))

    }

    render() {
        let baseUrl = ( true === window.location.href.includes("app_dev.php")) ? "/app_dev.php/" : "/"
        if (undefined == this.props.lang) {
            return <></>;
        }

        if (inIframe()) {
            return (<>
                <LiveTable />
            </>);
        }

        return (
            <ConnectedRouter history={history}>
                <RequestLoginModal />
                <> { /* your usual react-router v4/v5 routing */}
                    <Switch>
                        <Route exact path={baseUrl + "(fr|en)/" + NavigationEnum.LIVE} render={() => (
                            <Layout {...this.props} >
                                <LiveContent loginUrl={this.props.account} packsUrl={this.props.packsUrl}
                                             acoUrl={this.props.acoUrl}/>
                            </Layout>
                        )}/>
                        <Route path={baseUrl + "(fr|en)/" + NavigationEnum.REPLAY} render={() => (
                            <Layout {...this.props} >
                                <LiveContent loginUrl={this.props.account} packsUrl={this.props.packsUrl}
                                             acoUrl={this.props.acoUrl}/>
                            </Layout>
                        )}/>

                        <Route path={baseUrl + "(fr|en)/" + NavigationEnum.KEYNOTE} render={() => (
                            <Layout {...this.props} >
                                <div className="keynote-content">
                                    <LiveKeynote/>
                                    <LiveNews/>
                                </div>
                            </Layout>
                        )}/>

                        <Route path={baseUrl + "(fr|en)/" + NavigationEnum.RACE_EVENTS} render={() => (
                            <Layout {...this.props} >
                                <LiveRaceEvent/>
                            </Layout>
                        )}/>


                        <Route path={baseUrl + "(fr|en)/" + NavigationEnum.CAR_STATS} render={() => (
                            <Layout {...this.props} >
                                <CarStatsContainer/>
                            </Layout>
                        )}/>

                        <Route path={baseUrl + "(fr|en)/" + NavigationEnum.CAR_COMPARISON} render={() => (
                            <Layout {...this.props} >
                                <CarComparisonContainer/>
                            </Layout>
                        )}/>

                        <Route path={baseUrl + "(fr|en)/" + NavigationEnum.RACE_CONTROL} render={() => (
                            <Layout {...this.props} >
                                <StatScreenLayout title={t("race_control", this.props.lang)}
                                                  help="session_stats_help"
                                                  className="race-control"
                                                    >
                                    <RaceControlPage/>
                                </StatScreenLayout>
                            </Layout>
                        )}/>

                        <Route path={baseUrl + "(fr|en)/" + NavigationEnum.PIT_TIME} render={() => (
                            <Layout {...this.props} >
                                <StatScreenLayout title={t("total_pit_time", this.props.lang)}
                                                  help="total_pit_time_help">
                                    <EntryStints/>
                                </StatScreenLayout>
                            </Layout>
                        )}/>
                        <Route path={baseUrl + "(fr|en)/" + NavigationEnum.DRIVE_TIME} render={() => (
                            <Layout {...this.props} >
                                <StatScreenLayout title={t("drive_time", this.props.lang)} help="drive_time">
                                    <DriverStints/>
                                </StatScreenLayout>
                            </Layout>
                        )}/>

                        <Route path={baseUrl + "(fr|en)/" + NavigationEnum.SESSION_STATS} render={() => (
                            <Layout {...this.props} >
                                <StatScreenLayout title={t("session_stats", this.props.lang)}
                                                  help="session_stats_help">
                                    <SessionStats/>
                                </StatScreenLayout>
                            </Layout>
                        )}/>

                    </Switch>
                </>
            </ConnectedRouter>
        )
    }
}

//connect (function to access props, function to access function)(class to access)
const
    LiveMain = connect(mapStateToProps, storeDispatchToProps)(LiveMainView);
export default LiveMain;