export enum FlagsEnum {
    GREEN = "green",
    RED = "red",
    YELLOW = "yellow",
    FULL_YELLOW = "full_yellow",
    CHECK = "Chk",
    OFF = "off",
    SAFETY = "safety_car",
    VIRTUAL_SAFETY = "virtual_safety_car",
    INFO = "flag_info",
    FINISH = "finish"
}
