import ReferentialType, {
    ClassificationRowType,
    ClassificationType,
    PredictivePointsType,
    PredictiveRowType,
    ReferentialEntryType
} from "../../../type/ReferentialType";
import {ClassificationTypeEnum} from "../../../../../../../../../server/common/Enum/live/ClassificationTypeEnum";
import {t} from "../../../translation/translator";
import Entry from "../../../../../../../../../server/common/Models/live/Entry";

export const getColumnName = (classificationSelected: ClassificationType, lang: string) => {
    if (undefined === classificationSelected)
        return "";
    switch (classificationSelected.type) {
        case ClassificationTypeEnum.DRIVER:
            return t("driver", lang)
        case ClassificationTypeEnum.TEAM:
        case ClassificationTypeEnum.TEAM_LMP1:
            return t("team", lang)
        case ClassificationTypeEnum.MANUFACTURER:
            return t("manufacturer", lang)
    }
}

const getNameByType = (row: ClassificationRowType, type: ClassificationTypeEnum) => {
    switch (type) {
        case ClassificationTypeEnum.DRIVER:
            return row.key;
        case ClassificationTypeEnum.TEAM:
            return row.team;
        case ClassificationTypeEnum.TEAM_LMP1:
            return row.key;
        case ClassificationTypeEnum.MANUFACTURER:
            return row.key;
    }
}
//
// const getEntityByType = (referential: ReferentialType, row: ClassificationRowType, type: ClassificationTypeEnum): ReferentialEntryType | ReferentialBrandType | ReferentialDriverType | ReferentialTeamType => {
//     switch (type) {
//         case ClassificationTypeEnum.DRIVER:
//             return referential.drivers.find((driver, index) => driver.id.toString() === row.ecm_driver_id)
//         case ClassificationTypeEnum.TEAM:
//             return referential.entries.find((entry, index) => entry.number === row.key);
//         case ClassificationTypeEnum.TEAM_LMP1:
//             return referential.teams.find((team, index) => team.id.toString() === row.ecm_team_id);
//         case ClassificationTypeEnum.MANUFACTURER:
//             return referential.brands.find((brand, index) => brand.id.toString() === row.ecm_brand_id);
//     }
// }

/**
 * Renvoie les engagés du référentiel correspondant à une ligne de classement
 * Pour un classement driver : l'engagé qui contient ce driver (champ ecm_driver_id)
 * Pour un classement "team" (en fait, engagé) : l'engagé qui a ce numéro (champ key)
 * Pour un classement "manufacturer" : tous les engagés de cette marque (champ ecm_brand_id)
 * Pounr classement "team lmp1" : tous les engagés de la team (recherche par nom)
 *
 * @param entries Les engagés du référentiel
 * @param row La ligne du classement
 * @param type Le type de classement
 * @param code Le code du classement
 */
export const getEntriesByRow = (entries: Array<ReferentialEntryType>,
                                row: ClassificationRowType,
                                type: ClassificationTypeEnum, code: string): Array<ReferentialEntryType> => {
    switch (type) {
        case ClassificationTypeEnum.DRIVER:
            return entries
                .filter(e => e.drivers.find((id, index) => id.toString() == row.ecm_driver_id) != undefined);

        case ClassificationTypeEnum.TEAM_LMP1:
            // pas un classement par entry normalement... on renvoie les entries de la team
            return entries
                .filter(e => e.team.toString() == row.ecm_team_id);

        case ClassificationTypeEnum.TEAM:
            // classement engagé, on
            return entries.filter(e => e.number.toString() == row.key);

        case ClassificationTypeEnum.MANUFACTURER:
            // on sépare les gte et les lmp
            if (code == 'fiawec_gte') {
                // hypercar...
                return entries
                    .filter(e => e.brand.toString() === row.ecm_brand_id && (e.category === 4169));
            } else {
                return entries
                    .filter(e => e.brand.toString() === row.ecm_brand_id);
            }
    }
}

/**
 * Renvoie le nombre de points gagnés à cette position dans la barème de la course
 * @param position Position recherchée
 * @param points Barême de la course (en 0: points de toutes les autres positions)
 */
const getPointByPosition = (position: number, points: Array<number>) => {
    if (points[position])
        return points[position];
    else
        return points[0];
}

/**
 * Renvoie le nombre de points remportés par un engagé pour la course courante
 * @param referential Le référentiel (pour le bareme + la pole)
 * @param results Le tableau des résultats courants
 * @param entry L'engagé recherché
 */
const getEntryPoint = (referential: ReferentialType, results: Array<Entry>, entry: ReferentialEntryType, code: string): PredictivePointsType => {
    results = results.filter((entry, i) => null !== entry).sort((a, b) => a.ranking - b.ranking);

    if (!entry)
        return {
            race: 0,
            pole: 0,
            predictive: true
        };
    let poles = referential.poles;
    let points = referential.race.points;
    let result = results.find((r, i) => r.number.toString() == entry.number);

    if (!result)
        return {
            race: 0,
            pole: 0,
            predictive: true
        };

    let position: number;
    let concurrents: Entry[] = [];
    switch (code) {
        case "fiawec_driver":
            // pour un classement global GT (driver ou manufacturer), on ne prend pas la position dans la catégorie, mais on calcule la position de l'entry parmi les gte pro/gte am
            let categs = [2, 4];
            concurrents = results.filter((entry, ind) => categs.includes(entry.categoryId));
            position = concurrents.findIndex((entry, i) => entry.number === result.number) + 1;
            break;
        case "CHAMPIONSHIP":
            // pilotes hypercar :
            position = result.categoryRanking;
            break;
        case 'hypercar_wec':
            // Championnat du monde Manufacturers Hypercar :
            // classement parmi les voitures engagées :
            concurrents = results.filter(
                (entry, ind) => {
                    if (![4167, 4169].includes(entry.categoryId)) {
                        return false;
                    }
                    let refEntry = referential.entries.find(r => r.number == entry.number.toString());
                    if (!refEntry) {
                        return false;
                    }
                    console.log(refEntry);
                    // si on a trouvé l'engagé, on check qu'il fait le championnat du monde
                    return refEntry.world_championship;
                });
            position = concurrents.findIndex((entry, i) => entry.number === result.number) + 1;
            break;
        case 'trophy_hypercar':
            //  coupe hypercar : on cherche la place de l'engagé en ignorant les equipes officielles
            // on prend tous les engagés qui ne sont pas inscrits au championnat du monde
            concurrents = results.filter(
                (entry, ind) => {
                    if (![4167, 4169].includes(entry.categoryId)) {
                        return false;
                    }
                    let refEntry = referential.entries.find(r => r.number == entry.number.toString());
                    if (!refEntry) {
                        return false;
                    }
                    // si on a trouvé l'engagé, on check qu'il ne fait pas le championnat du monde
                    return !refEntry.world_championship;
                });
            position = concurrents.findIndex((entry, i) => entry.number === result.number) + 1;
            break;
        default:
            position = result.categoryRanking;
    }

    if (!position)
        return {
            race: 0,
            pole: 0,
            predictive: true
        };

    return {
        race: getPointByPosition(position, points),
        pole: (poles && poles.includes(parseInt(entry.number))) ? 1 : 0,
        predictive: true
    };
}

/**
 * Calcule le nombre de points de la course courante pour une ligne du classement
 * @param referential
 * @param results
 * @param entries
 * @param classificationCode
 * @param type
 */
const getCurrentPoint = (referential: ReferentialType, results: Array<Entry>, entries: Array<ReferentialEntryType>, type: ClassificationTypeEnum, classificationCode: string): PredictivePointsType => {
    console.log(type)
    switch (type) {
        case ClassificationTypeEnum.DRIVER:
            // on n'a qu'un entry :
            if (entries.length > 0) {
                let first = entries[Object.keys(entries)[0]];
                return getEntryPoint(referential, results, first, classificationCode);
            }
            break
        case ClassificationTypeEnum.TEAM:
            // on ne recoit qu'un engagé :
            if (entries.length > 0) {
                let first = entries[Object.keys(entries)[0]];
                return getEntryPoint(referential, results, first, classificationCode);
            }
            break
        case ClassificationTypeEnum.TEAM_LMP1:
            return aggregatePoints(referential, results, entries, classificationCode);
        case ClassificationTypeEnum.MANUFACTURER:
            return aggregatePoints(referential, results, entries, classificationCode);
    }
    return {
        race: 0,
        pole: 0,
        predictive: true
    };
}


/**
 * Fait la somme des points pour une ligne "multi-engagé" (classements Manufacturer et Team LMP1)
 * @param results
 * @param referential
 * @param entries
 */
const aggregatePoints = (referential: ReferentialType, results: Array<Entry>, entries: Array<ReferentialEntryType>, classificationCode: string): PredictivePointsType => {
    // on recoit tous les engagés :
    let totalPoint = 0;
    let totalPole = 0;
    let entryPoint: PredictivePointsType;

    // tableau de tous les points, pour ne garder que les 2 premiers
    let points = [];

    entries.map((e) => {
        entryPoint = getEntryPoint(referential, results, e, classificationCode);
        points.push(entryPoint.race);
        //totalPoint += entryPoint.point;
        totalPole += entryPoint.pole;
    });
    points.sort(function (a, b) {
        return b - a;
    });
    points = points.slice(0, 2);
    points.map((p) => totalPoint += p)

    return {
        race: totalPoint,
        pole: totalPole,
        predictive: true
    }
}

/**
 * Renvoie le tableau complet des points de la ligne : classement précédent + course courante
 * @param referentiel
 * @param results
 * @param classificationRow
 * @param raceIndex
 * @param entries
 * @param classificationCode
 */
const getPoints = (referentiel: ReferentialType, results: Array<Entry>, classificationRow: ClassificationRowType,
                   raceIndex: number, entries: Array<ReferentialEntryType>, type: ClassificationTypeEnum,
                   classificationCode: string) => {
    let points: Array<PredictivePointsType> = [];

    // on récupère tous les points des résultats précédents
    classificationRow
        .points_by_session
        .slice(0, raceIndex)
        .map(p => points.push({
            race: p.race_points,
            pole: p.pole_points
        }));
    // on ajoute les points de la course courante
    points.push((entries) ?
        getCurrentPoint(referentiel, results, entries, type, classificationCode) :
        {
            race: 0,
            pole: 0,
            predictive: true
        }
    );
    return points;

}

/**
 * Renvoie les données à afficher dans la ligne du tableau
 * @param referential
 * @param results
 * @param classificationRow
 * @param raceIndex
 * @param classificationSelected
 */
const getClassificationData = (referential: ReferentialType, results: Array<Entry>,
                               classificationRow: ClassificationRowType, raceIndex: number, classificationSelected: ClassificationType)
    : PredictiveRowType => {

    let type = classificationSelected.type;
    let code = classificationSelected.data.championship.code;
    if (classificationSelected.data.championship.name.indexOf('Hypercar World Endurance Manufacturers Championship') >= 0) {
        code = 'hypercar_wec';
    }

    let entries = getEntriesByRow(referential.entries, classificationRow, type, code);
    let points = getPoints(
        referential,
        results,
        classificationRow,
        raceIndex,
        entries,
        type,
        code
    );
    let total = 0;

    points.map(p => total += (p.pole + p.race));

    let result: PredictiveRowType = {
        old_position: classificationRow.position,
        name: getNameByType(classificationRow, type),
        points: points,
        total: total,
        category_label: (entries.length > 0) ? entries[0].category_label : ''
    }

    if (type === ClassificationTypeEnum.TEAM) {
        result.number = classificationRow.key
    }
    if (type === ClassificationTypeEnum.DRIVER) {
        // on va chercher le numéro de sa voiture
        if (entries.length > 0) {
            result.number = entries[0].number;
        }
    }
    return result;
}
/**
 * Renvoie les données à afficher
 * @param referential
 * @param entries
 * @param classificationSelected
 * @param raceIndex
 */
export const getResults = (referential: ReferentialType, entries: Array<Entry>, classificationSelected: ClassificationType, raceIndex: number) => {
    let results: PredictiveRowType[] = [];
    if (undefined === classificationSelected) {
        return []
    }
    if (classificationSelected.data) {
        classificationSelected
            .data
            .classification
            .map(c => results.push(
                getClassificationData(
                    referential,
                    entries,
                    c,
                    raceIndex,
                    classificationSelected
                )
            ));
    }
    if (classificationSelected.id === 2264 || classificationSelected.id === 2266) {
        // filtrer les lignes pas P/A :
        results = results.filter((row) => {
            return (row.category_label === 'LM P2 P/A');
        });
    }
    results = results.sort((row1, row2) => row2.total - row1.total);
    // calcul des new position
    let current_position = 0;
    let next_position = 0;
    let current_total = -1;
    let current_car = "";

    results.map(row => {

        // avancée différente pour un classement pilotes ou pas:
        if (classificationSelected.type !== ClassificationTypeEnum.DRIVER) {
            // classement "standard" : une position par ligne
            next_position++;
            if (current_total === row.total) {
                // ex-aequo : on ne bouge pas la position courante
            } else {
                // pas ex-aequo : on avance la position :
                current_position = next_position;
            }
        } else {

            // classement par pilotes : les pilotes d'une meme voiture avec le meme nombre de points ont la meme position,
            // sans faire avancer la position courante
            if (current_total === row.total) {
                // meme total : on vérifie si c'est la meme voiture
                if (current_car !== row.number) {
                    // pas la meme voiture : on incrémente les positions
                    next_position++;
                }
            } else {
                // pas le meme nombre de points : on avance
                next_position++;
                current_position = next_position;
            }
        }
        current_total = row.total;
        current_car = row.number;
        row.new_position = current_position;

    });

    return results;
}

export const addGlobalCategoryPositions = (results: Array<Entry>) => {
    let groups = {"lmp": [1, 3], 'gt': [2, 4]}
    let current = {"lmp": 0, "gt": 0}
    results.forEach((entry, index) => {
        if (null !== entry) {
        }
    })
    return results;
}