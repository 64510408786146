import React, {Component} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {t} from "../../translation/translator";
import {ReactSVG} from "react-svg";

type Props = {lang: string};

export default class Sharing extends Component<Props> {
    constructor(props) {
        super(props);
    }
    copy(text: string) {
        var textField = document.createElement('textarea')
        textField.innerText = text;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        toast(t('link_copied', this.props.lang));
        textField.remove()
    }
    render() {
        let url = window.location.href;
        return(
            <>
                <ToastContainer hideProgressBar={true} />
                <a rel="noopener" target='_blank' href={"https://www.facebook.com/sharer/sharer.php?u=" + url}>
                    <i className="fa fa-facebook-f fa-fw"></i>
                </a>
                <a rel="noopener" target="_blank" href={"https://twitter.com/share?url=" + url}>
                    <ReactSVG
                        src={"/bundles/front/svg/X_logo_2023.svg"}/>
                </a>
                <a onClick={() => {this.copy(url)}}>
                    <i className="fa fa-clone fa-fw"></i>
                </a>
            </>
        )
    }

}