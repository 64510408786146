import {t} from "../../../translation/translator";
import {FlagCell} from "./FlagCell";
import React from "react";
import Moment from "react-moment";
import FlagProgress from "../../../../../../../../../server/common/Models/live/FlagProgress";
import {FlagsEnum} from "../../../../../../../../../server/common/Enum/live/FlagsEnum";
import Params from "../../../../../../../../../server/common/Models/live/Params";
import {displayTime} from "../../../reducer/live/reducer";

type Props = {
    elapsedTime: number;
    totalTime: number;
    progressFlagState: Array<FlagProgress>;
    params: Params;
    lang: string;
}
export const Flags = (props: Props) => {

    let flags = [
        FlagsEnum.GREEN,
        FlagsEnum.FULL_YELLOW,
        FlagsEnum.SAFETY,
        FlagsEnum.VIRTUAL_SAFETY,
        FlagsEnum.RED
    ];

    let nbFlags = [];
    let percentFlags = [];

    flags.map((flag,index) => {
        nbFlags[flag] = 0;
        percentFlags[flag]= 0;
    })

    // on calcule le drapeau courant = celui entre le dernier changement de drapeau et maintenant
    let previousProgress = 0;
    let currentProgress = (props.elapsedTime / props.totalTime);
    props.progressFlagState.forEach((value, index) => {
        previousProgress += value.percentProgress / 100;
        nbFlags[value.state]++;
        percentFlags[value.state] += value.percentProgress / 100;
    });
    // si le dernier des previous est le meme que le courant, on ajoute au courant le précédent, et on l'enleve des previous
    if (props.progressFlagState.length > 0 && props.params.raceState === props.progressFlagState[props.progressFlagState.length - 1].state) {
        // on ajoute le temps courant au dernier
        previousProgress -= props.progressFlagState[props.progressFlagState.length - 1].percentProgress / 100;
    } else {
        // nouveau
        nbFlags[props.params.raceState]++;
    }

    currentProgress -= previousProgress;
    //percentFlags[props.params.raceState] += currentProgress;


    function getTime(percent: number) {
        return displayTime(props.totalTime * percent);
    }

    function getSince(percent: number) {
        let now = Date.now();
        let duration = props.totalTime * percent * 1000;
        return <Moment
            date={now - duration}
            format={"HH : mm : ss"}
        />
    }

    return (
        <div className="flags-stats">
            <div className="table-race">
                <table className="table table-race table-striped">
                    <thead>
                    <tr>
                        <th className="align-middle">{t("current", props.lang)}</th>
                        <th className="align-middle">{t("since", props.lang)}</th>
                        <th className="align-middle">{t("duration", props.lang)}</th>
                        {/*<th>{t("laps", props.lang)}</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="race-state">
                            <FlagCell state={props.params.raceState} lang={props.lang}/>
                        </td>
                        <td className="numeric align-middle">
                            {getSince(currentProgress)}
                        </td>
                        <td className="numeric align-middle">
                            {getTime(currentProgress)}
                        </td>
                        {/*<td></td>*/}
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="table-race">
                <table className="table table-race table-striped">
                    <thead>
                    <tr>
                        <th className="align-middle">{t("history", props.lang)}</th>
                        <th className="align-middle">{t("count", props.lang)}</th>
                        <th className="align-middle">{t("time", props.lang)}</th>
                        {/*<th>{t("totalLaps", props.lang)}</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {flags.map((flag, index) => {
                        return <tr key={index}>
                            <td className="race-state">
                                <FlagCell state={flag} lang={props.lang}/>
                            </td>
                            <td className="numeric align-middle">
                                {nbFlags[flag]}
                            </td>
                            <td className="numeric align-middle">
                                {displayTime(props.totalTime * percentFlags[flag])}
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}